@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

.sis-delegate {
  .action-btn {
    margin-bottom: 57px !important;
  }

  .center-last-row {
    display: flex;
    justify-content: center;

    .action-btn {
      margin-bottom: 4px !important;
    }
  }

  .topics-section .topic-container .desc.smaller {
    font-size: 18px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .hero__inner {
    padding-bottom: 115px;
  }

  .banner-img {
    width: 100%;
    max-width: 947px;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 55px;
  }

  .section-header-text {
    padding-top: 4px;
  }
}
