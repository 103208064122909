@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";
.unga-wrapper {
  .disclaimer {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    margin-top: 77px;
  }
  .preface-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .preface-text {
      font-size: 22px;
      line-height: 1.12;
      text-align: center;
      width: 90%;
      max-width: 600px;
      margin: auto;
      margin-bottom: 30px;
    }
  }

  .hero__inner {
    h1 {
      font-size: 75px;
    }

    p {
      font-size: 22px;
      line-height: 1.12;
    }

    .button.btn-std {
      font-size: 22px;
      padding-top: 20px;
      padding-bottom: 15px;

      &:not(.disabled):hover {
        border-color: #115381;
        background-color: #115381;
      }
    }
  }

  // for anchor link adjustments
  #events:target {
    padding-top: 165px; /* Adjust to match your navbar height */
    margin-top: -70px; /* Counteract padding to keep layout intact */
  }
  #events {
    transition: padding-top 0.3s;
  }

  .req-item.extended {
    .req-item-content {
      padding-left: 30px;
      padding-top: 19px;
      padding-bottom: 19px;

      .req-item-header {
        cursor: pointer;
        h5 {
          font-size: 20px;
        }
      }

      .short-desc {
        font-size: 16px;
        line-height: 1.6;

        .divider {
          margin-left: 8px;
          margin-right: 8px;
        }
      }

      .long-desc {
        font-size: 16px;
        line-height: 1.3;
      }

      // used in white bg containers (e.g. events page)
      &.alt {
        background-color: #e2faff;
        margin-bottom: 13px;

        &.selected {
          background-color: #a7e2ee;
        }

        .check__box.adj {
          background-color: #fff;
        }

        &:hover {
          background-color: #a7e2ee;
        }
      }
    }
  }

  .check__box.adj {
    // width: 27px;
    // height: 22px;
    // min-width: 27px;
    // min-height: 22px;
    margin-bottom: 0;
  }
  .company {
    padding: 70px 0;
    &__inner {
      &-slider {
        margin-bottom: 20px;
      }
      h3 {
        margin-bottom: 40px;
      }
    }
    &Item.top {
      img {
        max-height: 70px;
        height: 70px;
      }
    }
  }
  // 540px
  @mixin company-xsm-sm {
    .company {
      padding: 40px 0;
      &__inner {
        &-slider {
          margin-bottom: 20px;
        }
      }
    }
  }
  // 440px
  @mixin company-xxsm-xsm {
    .company {
    }
  }

  .companyItem.top {
    height: 70px;
  }

  .companyItem.top img {
    height: 70px;
  }

  &.redesign {
    background-color: #fff;

    .pink-bg-section {
      padding-top: 60px;
      background-color: #f8a7cd;
      width: 100%;
    }

    .request {
      background-color: #fff;
    }

    .option-header {
      width: 100%;
      height: auto;
      background-image: url(/public/images/unga/bg-pattern.png);
      background-repeat: no-repeat;
      background-size: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      min-height: 116px;
      display: flex;
      justify-items: center;
      align-items: center;
      padding: 26px;
      padding-left: 56px;
      padding-right: 56px;
      flex-direction: column;
    }

    .options-list {
      display: flex;
      flex-direction: column;

      .option-heading-text {
        color: #eb1bb9;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 24px;
      }

      .option-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        min-height: 200px;
        border-radius: 8px;
        margin-bottom: 46px;

        .option-header {
          width: 100%;
          height: auto;
          background-image: url(/public/images/unga/bg-pattern.png);
          background-repeat: no-repeat;
          background-size: cover;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          min-height: 116px;
          display: flex;
          justify-items: center;
          align-items: center;
          padding: 26px;
          padding-left: 56px;
          padding-right: 56px;
          flex-direction: column;

          &.logo {
            flex-direction: row;
            justify-content: space-between;

            .left {
              width: 80%;
              flex-direction: column;
              justify-items: center;
              align-items: center;
            }

            .right {
              width: 20%;
              display: flex;
              flex-direction: column;
              justify-items: flex-start;
              align-items: center;
              .logo-title {
                font-size: 16px;
                margin-bottom: 5px;
                color: #fff;
                text-align: left;
                width: 100%;
              }
              .logo-img {
                width: 100%;
                &.mt {
                  margin-top: 10px;
                }
              }
            }
          }

          .text {
            color: #fff;
            font-size: 43px;
            font-weight: 700;
            // text-transform: uppercase;
            text-align: left;
            margin: auto;
            line-height: 1;
            transform: translateY(5px);
            width: 100%;

            &.prefix {
              font-size: 24px;
              margin-bottom: 10px;
              text-transform: uppercase;
            }

            &.postfix {
              text-transform: none;

              font-size: 24px;
              font-weight: 300;
              font-style: italic;
              letter-spacing: 0.6px;
            }
          }
        }
        .option-body {
          padding-top: 50px;
          padding-bottom: 33px;
          padding-left: 56px;
          padding-right: 56px;
          display: flex;
          flex-direction: column;
          color: #000000;
          font-size: 26px;

          .upper-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .date-time-container {
              display: flex;
              flex-direction: column;
              font-weight: 500;
              text-align: left;
              line-height: 1;

              .date.slim {
                font-weight: 300;
                text-align: right;
              }

              .location {
                text-align: right;
                font-weight: 500;
                text-transform: uppercase;
              }
            }
          }

          .description {
            padding-top: 40px;
            text-align: left;
            font-weight: 400;
            line-height: 1.4;
            p {
              &:not(:first-child) {
                margin-top: 10px;
              }
              a {
                display: inline;
                // text-decoration: underline !important;
                color: #8748f0;
                font-weight: 400;
                line-height: 1.4;
                font-size: 26px;
                letter-spacing: normal;
              }
            }
          }
        }
      }
    }

    .hero__inner {
      min-height: unset;
      // min-height: 700px;
      h1 {
        line-height: 88px;
        text-align: center;
        max-width: unset;
        width: 100%;
        margin: auto;
        margin-bottom: 27px;
        margin-left: 0;
        // text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
        text-align: left;
      }
    }

    .hero-row-lower {
      // transform: translateY(-116px);
    }

    .hero-container {
      padding-top: 74px;
      padding-bottom: 75px;
      .hero-logos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 850px;
        margin: auto;
        margin-bottom: 46px;
        margin-bottom: 55px;
        margin-left: 0;

        .lm-logo {
          max-width: 180px;
          transform: translateY(-10px);
        }

        .un-logo {
          max-width: 110px;
        }

        .sis-logo {
          max-width: 300px;
        }
      }
    }

    .pink-btn {
      background: rgb(254, 20, 168);
      background: linear-gradient(
        90deg,
        rgba(254, 20, 168, 1) 0%,
        rgba(166, 54, 247, 1) 100%
      );
      &.no-grad {
        background: rgb(254, 20, 168);
      }
      font-size: 32px;
      font-weight: 700;
      color: #fff;
      padding: 100px;
      padding-top: 25px;
      padding-bottom: 15px;
      border-radius: 42px;
      border: 0;
      margin: auto;
      margin-top: 17px;
      min-width: 300px;
      text-align: center;
      filter: brightness(1);

      &.simple {
        border-radius: 12px;
        padding: 80px;
        padding-top: 20px;
        padding-bottom: 10px;
        min-width: 270px;
      }

      &:hover {
        filter: brightness(0.92);
        opacity: 1 !important;
      }
    }

    .submit-success-msg {
      font-size: 23px;
      line-height: 1.5;
      color: #f10073;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }

    .requestForm {
      max-width: unset;
      border-radius: 8px;

      h3 {
        font-size: 38px;
        margin-bottom: 34px;
        &:not(:first-child) {
          margin-top: 14px;
        }
      }

      h5.partner-text {
        font-size: 18px;
        margin-top: 0;
        font-weight: 400;
        margin-bottom: 25px;
        // margin-top: 53px;
        color: #000000;
      }
    }

    .check {
      margin-top: 20px;
    }

    .check__box {
      margin: 0 39px 9px 0;
      width: 19px;
      height: 19px;
      min-width: 19px;
      min-height: 19px;

      span {
        border: 2px solid #717171;
      }
    }

    .check-label {
      font-size: 18px;
      margin-top: 0;
      font-weight: 400;
      transform: translateY(-2px);
      .purple-link {
        display: inline-block;
        color: #d261db;
        text-decoration: underline !important;
        letter-spacing: 1;
        font-size: 18px;
        margin-top: 0;
        font-weight: 400;
      }
    }

    .check__box input:checked ~ span {
      background-color: #d261db;

      img {
        // -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        // filter: invert(100%);
      }
    }

    .cap-width {
      width: 100%;
      max-width: 920px;
      margin: auto;

      &.slightly-wider {
        max-width: 1000px;
      }
    }

    .header-img {
      width: 100%;
      padding: 10px;
      max-width: 600px;
    }

    .header-text-container {
      height: 100%;
      display: flex;
      align-items: center;
      .header-text {
        color: #000;
        font-size: 27px;
        line-height: 33px;
        padding: 12px;
      }
    }

    .hero__bg {
      top: -80px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 25px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 10px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      img {
        width: 100%;
        object-fit: cover;
        // object-fit: fill;
        // -o-object-fit: cover;
        object-position: unset;
      }
    }
    .hero-btns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 80px;

      .hero-btn {
        .btn-std.pink {
          background-color: #ff2f92;
          font-size: 28px;
          color: #fff;
          padding-top: 25px;
          padding-bottom: 15px;
          text-align: center;

          &:hover {
            background-color: #df2e84;
          }
        }
        &:first-child {
          margin-right: auto;
        }
      }
    }
    @media (max-width: 767px) {
      .show-mob {
        display: flex;
      }
      .hide-mob {
        display: none;
      }
      .hero__inner h1 {
        font-size: 74px;
      }
      .hero-btns {
        flex-direction: column;
        justify-content: center;
        margin-top: 68px;
        margin-bottom: 150px;
        .hero-btn {
          margin: auto;
          margin-bottom: 70px;
          &:first-child {
            margin: auto;
          }
        }
      }

      .header-text-container .header-text {
        margin-top: 50px;
      }

      .options-list .option-container {
        width: 92%;
        margin: auto;
        margin-bottom: 36px;
        /* margin-left: 20px; */
        /* margin-right: 20px; */
      }
      label.input__outer.sm:not(:first-child) {
        margin-top: unset !important;
      }
      label.input__outer.sm {
        margin-bottom: 42px;
      }
      .requestForm h3 {
        margin-top: 40px !important;
      }
      .requestForm .input__outer.sm {
        width: 100%;
      }
      .requestForm .input__outer h5 {
        margin-bottom: 3px;
        font-size: 20px;
      }

      .check {
        margin-top: 55px;
      }
      .check-label {
        font-size: 20px;
        line-height: 1.3;
      }
      .requestForm h5.partner-text {
        margin-bottom: 61px;
        margin-top: 88px;
        font-size: 26px;
        line-height: 1.3;
      }
      .form-invalid-msg {
        font-size: 20px;
        margin-top: 24px;
        text-align: center;
      }
      .requestForm {
        padding: 34px 26px 32px;
      }
      .options-list .option-container {
        width: 100%;
        .option-header {
          padding-left: 26px;
          padding-right: 26px;
          .text.prefix {
            font-size: 30px;
          }

          &.logo {
            flex-direction: column;
            justify-content: center;

            .left {
              width: 100%;
            }

            .right {
              width: 50%;
              padding-top: 26px;
              .logo-title {
                font-size: 22px;
                margin-bottom: 5px;
                color: #fff;
                text-align: center;
                width: 100%;
              }
              .logo-img {
              }
            }
          }
        }
        .option-body {
          padding-left: 26px;
          padding-right: 26px;
          .upper-container {
            flex-direction: column;
            align-items: flex-start;
            .date-time-container {
              .date {
                margin-bottom: 15px;
                text-align: left !important;
              }
              &:last-child {
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }

  .partners-section {
    background-color: #fff;
    width: 100%;
    padding: 26px;

    .inner {
      background-color: #f2f2f2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 40px;

      .header-img {
        width: 100%;
        max-width: 740px;
        margin: auto;
        background-image: url(/public/images/unga/rect-grad.png);
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 23px;
        text-align: center;
        color: #fff;
        height: 102px;
        border-radius: 12px;
        margin-bottom: 16px;
        .header-img-text {
          font-size: 40px;
          letter-spacing: 1px;
          font-weight: 700;
          margin: auto;
          margin-bottom: 12px;
        }
      }

      .gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 10px; /* Adjust the gap between images */
        justify-content: center; /* Center the images horizontally */
        padding: 10px;
        margin-bottom: 50px;

        .gallery-item {
          width: calc(
            (100% / 3) - 10px
          ); /* Adjust width and margin for each image */
          // object-fit: cover; /* Ensure the images cover the allocated space */
          background-color: #fff;
          padding: 25px;
          // padding-top: 38px;
          // padding-bottom: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 150px;
        }

        @media (max-width: 768px) {
          .gallery-item {
            width: calc((100% / 2) -10px); /* Adjust for smaller screens */
          }
        }

        @media (max-width: 480px) {
          .gallery-item {
            width: 100%; /* Stack images on very small screens */
          }
        }
      }
    }
  }

  .footer-section {
    background-color: #8748f0;
    padding: 110px;
    padding-left: 46px;
    padding-right: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #fff;
    margin-top: 30px;

    .footer-header {
      font-size: 40px;
      letter-spacing: 1px;
      font-weight: 700;
      margin: auto;
      margin-bottom: 12px;
    }

    .footer-sub-header {
      margin: auto;
      font-size: 22px;
      letter-spacing: 0.5px;
      font-weight: 300;
    }
  }
}

.ces-header {
  position: absolute;
  background-color: #002b4900;
}

@media (max-width: 768px) {
  .unga-wrapper {
    .disclaimer {
    }
    .preface-container {
      .preface-text {
      }
    }

    .hero__inner {
      h1 {
      }

      p {
      }

      .button.btn-std {
        &:not(.disabled):hover {
        }
      }
    }

    #events:target {
    }
    #events {
    }

    .req-item.extended {
      .req-item-content {
        .req-item-header {
          h5 {
          }
        }

        .short-desc {
          .divider {
          }
        }

        .long-desc {
        }

        &.alt {
          &.selected {
          }

          .check__box.adj {
          }

          &:hover {
          }
        }
      }
    }

    .check__box.adj {
    }
    .company {
      &__inner {
        &-slider {
        }
        h3 {
        }
      }
      &Item.top {
        img {
        }
      }
    }
    @mixin company-xsm-sm {
      .company {
        &__inner {
          &-slider {
          }
        }
      }
    }
    @mixin company-xxsm-xsm {
      .company {
      }
    }

    .companyItem.top {
    }

    .companyItem.top img {
    }

    &.redesign {
      .pink-bg-section {
      }

      .request {
      }

      .option-header {
        border-radius: 0 !important;
      }

      .options-list {
        .option-heading-text {
        }

        .option-container {
          border-radius: 0;
          &:not(.top) {
            border: 6px solid #9e4fea;
          }
          .option-header {
            border-radius: 0 !important;
            &.logo {
              .left {
              }

              .right {
                .logo-title {
                }
                .logo-img {
                  &.mt {
                  }
                }
              }
            }

            .text {
              font-size: 38px;
              &.prefix {
                font-size: 28px;
              }

              &.postfix {
              }
            }
          }
          .option-body {
            .upper-container {
              .date-time-container {
                .date.slim {
                }

                .location {
                }
              }
            }

            .description {
            }
          }
        }
      }

      .hero__inner {
        h1 {
          font-size: 59px;
          text-align: center;
        }
      }

      .hero-row-lower {
      }

      .hero-container {
        .hero-logos {
          flex-direction: column;
          justify-content: center;
          .lm-logo {
            margin: auto;
          }

          .sis-logo,
          .un-logo {
            margin: auto;
            margin-top: 25px;
          }
        }
      }

      .pink-btn {
        &.no-grad {
        }
        &:hover {
        }
      }

      .submit-success-msg {
      }

      .requestForm {
        h3 {
          &:not(:first-child) {
          }
        }

        h5.partner-text {
          margin-top: 20px;
        }
      }

      .check {
      }

      .check__box {
        span {
        }
      }

      .check-label {
        .purple-link {
        }
      }

      .check__box input:checked ~ span {
        img {
        }
      }

      .cap-width {
        &.slightly-wider {
        }
      }

      .header-img {
      }

      .header-text-container {
        .header-text {
        }
      }

      .hero__bg {
        img {
        }
      }
      .hero-btns {
        .hero-btn {
          .btn-std.pink {
            &:hover {
            }
          }
          &:first-child {
          }
        }
      }
      @media (max-width: 767px) {
        .show-mob {
        }
        .hide-mob {
        }
        .hero__inner h1 {
        }
        .hero-btns {
          .hero-btn {
            &:first-child {
            }
          }
        }

        .header-text-container .header-text {
        }

        .options-list .option-container {
          /* margin-left: 20px; */
          /* margin-right: 20px; */
        }
        label.input__outer.sm:not(:first-child) {
        }
        label.input__outer.sm {
        }
        .requestForm h3 {
        }
        .requestForm .input__outer.sm {
        }
        .requestForm .input__outer h5 {
        }

        .check {
        }
        .check-label {
        }
        .requestForm h5.partner-text {
        }
        .form-invalid-msg {
        }
        .requestForm {
        }
        .options-list .option-container {
          .option-header {
            border-radius: 0 !important;

            .text.prefix {
            }

            &.logo {
              .left {
              }

              .right {
                .logo-title {
                }
                .logo-img {
                }
              }
            }
          }
          .option-body {
            .upper-container {
              .date-time-container {
                .date {
                }
                &:last-child {
                }
              }
            }
          }
        }
      }
    }

    .partners-section {
      .inner {
        .header-img {
          height: fit-content;
          .header-img-text {
            line-height: 1;
          }
        }

        .gallery {
          .gallery-item {
            // object-fit: cover; /* Ensure the images cover the allocated space */
            // padding-top: 38px;
            // padding-bottom: 38px;
          }

          @media (max-width: 768px) {
            .gallery-item {
            }
          }

          @media (max-width: 480px) {
            .gallery-item {
            }
          }
        }
      }
    }

    .footer-section {
      .footer-header {
        font-size: 34px;
        line-height: 1;
      }

      .footer-sub-header {
      }
    }
  }
}
