@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

.industrial-tech-new.specificity-adder-1.specificity-adder-2 {
  .request-section {
    transform: translateY(-80px);
  }
  .pink-btn {
    margin-top: 0;
  }

  .form-invalid-msg {
    text-align: center;
  }

  .hero-row-lower {
    transform: translateY(-61px);
  }

  .hero-container {
    padding-top: 0;
  }

  .hero__inner h1 {
    line-height: 88px;
    text-align: center;
    max-width: 1080px;
    margin: auto;
    margin-bottom: 27px;
    text-shadow: 1px 4px 4px #00000061;

    &.greeting {
      font-size: 60px;
      line-height: 60px;
    }
  }

  .header-text-container .header-text {
    max-width: 800px;
    margin: auto;
  }

  .hero__inner {
    min-height: 563px;
  }

  .option-container .content-container {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 20px;

    .startup-title {
      font-size: 18px;
      font-weight: 600;
    }

    .startup-short-desc {
      font-weight: 500;
    }
  }

  .options-list .option-container {
    min-height: unset;
  }

  .option-container .outer-container .checkbox-container {
    padding: 20px;
    display: flex;
    align-items: center;
  }
}
