@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: $main;
  transform: translateX(100%);
  padding: 65px 0 180px;
  transition: 0.6s ease;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-thumb {
    background: $primary-dark;
    border-radius: 10px;
  }
  &.active {
    @include transformReset;
  }

  &__lines {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
    overflow: hidden;
  }
  &__close {
    z-index: 5;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $primary;
    position: relative;
    margin: 0 0 0 auto;
    cursor: pointer;
    transition: 0.3s ease;
    margin-bottom: 25px;
    &:hover {
      background-color: $primary-dark;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 3px;
      background-color: $main;
      transform: translate(-50%, -50%) rotate(45deg);
      content: "";
      transition: 0.3s ease;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 3px;
      background-color: $main;
      transform: translate(-50%, -50%) rotate(-45deg);
      content: "";
      transition: 0.3s ease;
    }
  }
  &__inner {
    flex-direction: column;
    color: $white;
    &-row {
      display: flex;
      justify-content: space-evenly;
    }
    &-links {
      width: calc(33.3% - 15px);
      max-width: 260px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      a {
        font-size: 30px;
        line-height: 100%;
        margin-bottom: 30px;
        color: $primary;
        position: relative;
        &:last-child {
          margin: 0;
        }
        &:hover {
          opacity: 1;
          color: $white;
          &::before {
            width: 100%;
          }
        }
        &::before {
          position: absolute;
          content: "";
          top: 100%;
          left: 0;
          width: 0;
          height: 4px;
          background-color: $primary-dark;
          transition: 0.4s ease;
        }
      }
    }
    &-social {
      width: calc(33.3% - 15px);
      max-width: 180px;
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      h4 {
        margin-bottom: 5px;
        color: $primary;
      }
      a {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: #ddc9a3;
        font-size: 22px;
        line-height: 100%;
        &:hover {
          opacity: 1;
          color: $white;
        }
        span {
          width: 22px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 10px 7px 0;
          svg {
            width: 100%;
            height: 100%;
            color: $primary;
            min-width: 22px;
          }
        }
      }
    }
    &-col {
      max-width: 260px;
      width: calc(33.3% - 15px);
      h4 {
        font-weight: 400;
        color: $primary;
        margin-bottom: 5px;
      }
      p {
        color: #ddc9a3;
        margin-bottom: 15px;
      }
      .button {
        &.big {
          margin-bottom: 40px;
          padding: 13px 34px 7px;
          border-radius: 15px;
          background-color: $primary-dark;
          font-size: 24px;
          line-height: 100%;
          color: $white;
          font-weight: 400;
          border: 2px solid $primary-dark;
          &:hover {
            background-color: transparent;
          }
        }
        &.sm {
          font-size: 14px;
          border-radius: 10px;
          padding: 9px 16px 5px;
          line-height: 100%;
        }
      }
      .input {
        margin-bottom: 20px;
        input {
          padding: 8px 18px 3px;
          background-color: transparent;
          border-radius: 15px;
          border: 1px solid $primary;
          color: $white;
          font-size: 14px;
          line-height: 100%;
          &::placeholder {
            color: $primary;
          }
        }
      }
    }
  }
}
.burger {
  display: block;
  cursor: pointer;
  @include transition;
  position: relative;
  width: 26px;
  height: 20px;
  margin-left: 28px;
  &:hover {
    opacity: 0.8;
  }
  &::before {
    top: 0%;
    width: 100%;
    height: 3px;
    background-color: $primary;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    @include transformX;
  }
  span {
    top: 50%;
    width: 100%;
    height: 3px;
    background-color: $primary;
    transition: all 0.3s ease;
    position: absolute;
    left: 50%;
    @include transform;
    @include transition;
  }
  &::after {
    bottom: 0%;
    width: 50%;
    height: 3px;
    background-color: $primary;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 0;
  }
}
// 1024px
@mixin nav-lg-xlg {
  .nav {
    padding: 30px 0 160px;
    &__lines {
      width: 400px;
      height: 400px;
    }
    &__close {
      width: 46px;
      height: 46px;
      margin-bottom: 20px;
    }
    &__inner {
      &-links {
        a {
          font-size: 24px;
          margin-bottom: 22px;
        }
      }
      .button {
        &.big {
          margin-bottom: 28px;
        }
      }
    }
  }
}
// 750px
@mixin nav-sm-md {
  .nav {
    padding: 20px 0 140px;
    &__lines {
      width: 300px;
      height: 300px;
      bottom: unset;
      opacity: 0.2;
    }
    &__inner {
      &-row {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 25px;
      }
      &-links {
        width: calc(55% - 10px);
      }
      &-social {
        width: calc(45% - 10px);
        max-width: 240px;
      }
      &-col {
        width: 100%;
        max-width: 330px;
        margin-top: 40px;
      }
      .button {
        &.big {
          font-size: 22px;
          border-radius: 10px;
        }
      }
    }
  }
}
// 540px
@mixin nav-xsm-sm {
  .nav {
    &__close {
      margin-bottom: 5px;
      width: 40px;
      height: 40px;
    }
    &__inner {
      &-row {
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
      }
      &-links {
        width: 100%;
        max-width: unset;
      }
      &-social {
        width: 100%;
        max-width: unset;
        margin-top: 30px;
        a {
          font-size: 20px;
          line-height: 100%;
          span {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
        }
      }
      &-col {
        max-width: unset;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .button {
        &.big {
          width: 100%;
          margin-top: 60px;
          order: 2;
        }
        &.sm {
          width: 100%;
        }
      }
      .input {
        width: 100%;
      }
    }
  }
}
