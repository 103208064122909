@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

.sis-omni {
  .section-header-text {
    padding-top: 8px;
  }
  .sis-omni-logo {
    width: 90%;
    max-width: 300px;
    margin: auto;
    text-align: center;
    margin-bottom: 8px;
  }

  .action-btn {
    // margin-bottom: 57px !important;
  }

  .center-last-row {
    display: flex;
    justify-content: center;

    .action-btn {
      margin-bottom: 4px !important;
    }
  }

  .topics-section .topic-container .desc.smaller {
    font-size: 18px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .hero__inner {
    padding-bottom: 115px;
  }

  .req-item {
    .req-item-content {
      padding-left: 30px;

      // used in white bg containers (e.g. events page)
      &.alt {
        background-color: #e2faff;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 13px;

        &.selected {
          background-color: #a7e2ee;
        }

        .check__box.adj {
          background-color: #fff;
        }

        &:hover {
          background-color: #a7e2ee;
        }
      }
    }
  }

  .check__box.adj {
    // width: 27px;
    // height: 22px;
    // min-width: 27px;
    // min-height: 22px;
    margin-bottom: 0;
  }

  .request.last {
    padding-top: 0;
  }

  .company {
    padding: 31px 0;
  }

  .about-you-container {
    background-color: #fff;
    padding: 30px 30px 30px 30px;

    .input input {
      background-color: #f3f3f3 !important;
    }
  }

  .submit-btn {
    margin-top: 22px;
  }

  @media (max-width: 991px) {
    .topic-container {
      img {
        margin-bottom: 52px !important;
      }
    }
  }
}

.req-accordion {
  padding-bottom: 22px;

  a {
    display: inline;
    text-decoration: underline !important;
    font-size: unset;
    letter-spacing: unset;
    line-height: unset;
    color: #125bcf;
  }

  p {
    font-size: 16px;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 2px;
    line-height: 1;
  }
  ul {
    // list-style-type: disc; // You can use other list-style types like 'circle' or 'square'

    li {
    }
  }
}

.learn-more-accordion-toggle {
  padding-bottom: 20px;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 1rem;
}
