@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

.dealmakers-ib {
  .requestForm {
    padding: 12px 64px 22px;

    &.first {
      padding-top: 40px;
    }

    &.lg-pt {
      padding-top: 34px;
    }
  }
}
