@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";
.ed-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.15rem;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  flex: 1;

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  p {
    white-space: pre-wrap;
  }

  .section-heading {
    font-size: 3.7rem;
    font-weight: 400;
    &.sm {
      font-size: 3.25rem;
    }
  }

  background-color: #f2f2f2;

  .footer-image-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    width: 100%;
    margin-top: 100px;

    .footer-image {
      width: 100%;
      // padding-top: 56.75%;
      position: relative;
      // border-radius: 0.5rem;
      // overflow: hidden;
      img {
        // object-fit: cover;
        // z-index: 10;
        // position: absolute;
        // inset: 0%;
        width: 100%;
        // height: 100%;
        // max-width: 100%;
        // display: inline-block;
        // vertical-align: middle;
      }
    }
  }

  .reg-closed-big-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .reg-closed-divider {
      width: 100%;
      height: 1px;
      background-color: #7575753b;
      &.top {
      }

      &.bottom {
      }
    }

    .reg-closed-big {
      font-weight: 500;
      font-size: 26px;
      color: #757575;
      margin-top: 25px;
      margin-bottom: 25px;
      text-align: center;
      width: 100%;
    }
  }

  .reg-closed-small {
    font-weight: 700;
    color: #757575;
  }

  .rsvp-button {
    display: flex;
    justify-content: center;
    --theme-primary-color: #61999d;
    background-color: #61999d;
    background-color: var(--theme-primary-color);
    color: #fff;
    padding: 18px 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s;
    font-weight: 400;
    font-size: 1.15rem;
    border-radius: 8px;
    max-width: 172px;
    letter-spacing: 1.3px;

    // &:hover {
    //   background-color: #487073;
    //   background-color: var(--theme-primary-hover-color);
    // }
  }

  .ed-block-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;

    .ed-vertical-flex {
      display: flex;
      grid-column-gap: 4rem;
      grid-row-gap: 4rem;

      .ed-left-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .ed-right-rail {
        flex: none;
        max-width: 100%;
        width: 500px;
        display: block;
        // display: flex;
        flex-direction: column;
        box-sizing: border-box;
        display: block;
        flex-basis: auto;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;

        .sticky-content {
          z-index: 1;
          min-height: auto;
          position: sticky;
          position: -webkit-sticky;
          min-height: auto;
          top: 150px;
          // align-items: flex-start;
          margin: auto;
          padding: 0;
          align-self: flex-start;
        }
      }
    }
  }

  .ed-main {
    width: 100%;
  }

  .horizontal-rule {
    background-color: #dcd9d0;
    width: 100%;
    height: 1px;
  }

  .header-bar {
    width: 100%;
    padding-top: 100px;
    background-color: #fff;
    padding: 20px 0;
    border-bottom: #a7a7b3 2px solid;

    .header-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .header-title {
        font-size: 1.6rem;
        letter-spacing: 2.2px;
        font-weight: 400;
      }

      .header-logos {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 35px;

        .header-logo {
          max-width: 156px;

          &.lg {
            max-width: 170px;
          }

          &:not(:first-child) {
            // margin-left: 10px;
          }
        }
      }
    }
  }

  .ed-hero {
    padding: 40px 0;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .hero-info-container {
      .hero-location {
        grid-column-gap: 0.5rem;
        grid-row-gap: 0.4rem;
        color: #2f2b24;
        letter-spacing: -0.02rem;
        text-transform: capitalize;
        border-bottom: 1px solid #2f2b244d;
        flex: none;
        justify-content: center;
        align-items: center;
        padding-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 100%;
        display: flex;
        width: fit-content;
        margin: auto;
        margin-left: 0;
        margin-top: 30px;

        img {
          background-color: #0000;
          flex: none;
          justify-content: center;
          align-items: center;
          width: 1rem;
          height: 1rem;
          display: flex;
          max-width: 100%;
        }
      }
      .hero-heading {
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 16px;
        span {
          font-style: italic;
          font-weight: 400;
        }
      }

      .hero-subheading {
        font-size: 1.15rem;
        line-height: 160%;
        font-weight: 700;
        margin-bottom: 30px;
      }

      .hero-text {
        font-size: 1.15rem;
        line-height: 160%;
        font-weight: 400;
        margin-bottom: 30px;
      }

      .expect-bullets {
        margin: 40px 0;
        padding-left: 20px;

        li {
          margin-bottom: 10px;
          list-style-type: disc;
        }
      }

      .expect-disclaimer {
        margin-top: 30px;
        // font-weight: 700;
        margin-bottom: 0px;
        font-style: italic;
      }
    }

    // .hero-image-container {
    //   max-width: 40%;
    //   .hero-image {
    //     width: 100%;
    //     max-width: 500px;
    //     img {
    //       border-radius: 12px;
    //     }
    //   }
    // }
  }

  // .roboto-regular {
  //   font-family: "Roboto", sans-serif;
  //   font-weight: 300;
  //   font-style: normal;
  // }

  .ed-event-info {
    // position: fixed;
    // left: 0;
    // bottom: 0;
    width: 100%;
    background-color: #fff;
    border: #dcd9d0 2px solid;
    border-radius: 20px;
    z-index: 10000;
    border-width: 1px;
    border-color: #dcd9d0;
    font-size: 1.15rem;
    line-height: 160%;
    padding: 40px 20px;
    align-items: stretch;
    padding: 2rem;

    .event-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 26px;

      .event-image {
        width: 100%;
        width: 100%;
        padding-top: 56.75%;
        position: relative;
        border-radius: 0.5rem;
        overflow: hidden;
        img {
          object-fit: cover;
          z-index: 10;
          position: absolute;
          inset: 0%;
          width: 100%;
          height: 100%;
          max-width: 100%;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .event-info-container {
        width: 100%;

        .event-details-heading {
          font-size: 1.15rem;
          font-weight: 700;
          letter-spacing: 2.5px;
          margin-bottom: 20px;
        }

        .event-info {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: auto;
          margin-bottom: 20px;

          .event-info-text {
            margin-bottom: 10px;
            font-weight: 500;

            .start {
              font-weight: 700;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .ed-expect {
    padding: 40px 0;

    .expect-title {
      margin-bottom: 20px;
    }

    .expect-text {
      margin-bottom: 20px;
    }

    .expect-bullets {
      margin: 40px 0;
      padding-left: 20px;

      li {
        margin-bottom: 10px;
        list-style-type: disc;
      }
    }

    .expect-disclaimer {
      margin-top: 20px;
      font-weight: 700;
      margin-bottom: 60px;
    }

    .ed-faqs {
      margin-top: 50px;

      .ed-faq-title {
        margin-bottom: 20px;
      }

      .faq {
        border-bottom: 2px solid #7c8c93;

        &.first {
          border-top: 2px solid #7c8c93;
        }

        .faq-head {
          padding: 25px 60px 25px 0;
          cursor: pointer;
          transition: 0.3s ease;
          position: relative;
          font-weight: 600;

          &.active::before {
            transform: translateY(-40%) rotate(-45deg);
          }

          &::before {
            position: absolute;
            top: 50%;
            right: 20px;
            content: "";
            width: 16px;
            height: 16px;
            border-top: 3px solid var(--theme-primary-color);
            border-right: 3px solid var(--theme-primary-color);
            transform: translateY(-70%) rotate(135deg);
            transition: 0.3s ease;
          }
        }

        .faq-body {
          max-height: 0;
          opacity: 0;
          transition: 0.4s ease;
          overflow: hidden;

          &.active {
            padding: 0 0 30px 0;
            opacity: 1;
            max-height: 400px;
          }
        }

        p {
          font-size: 1.15rem;
          line-height: 160%;

          a {
            display: inline-block;
            color: #015637;
            font-weight: 500;
          }
        }
      }
    }
  }

  .footer-spacer {
    height: 100px;
  }

  .ed-footer {
    background-color: #fff;
    border-top: 2px solid #a7a7b3;
    min-height: 300px;
  }
}
@keyframes scale-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.modal.scale-in .modal-dialog {
  animation: scale-in 0.3s ease-out;
}

.modal.scale-out .modal-dialog {
  animation: scale-out 0.3s ease-out;
}

@keyframes scale-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
.rsvp-modal {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;

  .form-control {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .form-control:focus {
    border-color: #61999d;
    outline: 0;
    box-shadow: none;
  }

  .check__box span {
    border: 2px solid #61999d;
  }

  .check__box input:checked ~ span {
    background-color: #61999d;
  }

  .legal-notice {
    text-align: center;
    color: #000;
    font-size: 10px !important;
    max-width: 360px;
    margin: auto;
    margin-bottom: 0 !important;
    a {
      font-size: 10px !important;
    }
  }

  .modal-content {
    padding: 50px;
    padding-left: 38px;
    padding-right: 38px;
    border: #dcd9d0 2px solid;
    border-radius: 20px;
    z-index: 10000;
    border-width: 1px;
    border-color: #dcd9d0;
  }

  .rsvp-modal-title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;

    &.sm {
      font-size: 2rem;
    }
  }

  .rsvp-submitted-text {
    font-size: 1.1rem;
  }

  .rsvp-modal-close {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: unset;
    top: 10px;
    right: 14px;
    margin: auto;
    transition: color 0.2s;
    font-size: 22px;
    cursor: pointer;
    &:hover {
      color: #ba1414;
    }
  }

  .register-button {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    background-color: #61999d;
    background-color: var(--theme-primary-color);
    color: #fff;
    padding: 12px 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s;
    font-weight: 400;
    font-size: 22px;
    border-radius: 8px;
    width: 100%;
    letter-spacing: 1.3px;

    &:hover {
      // background-color: #487073;
    }
  }

  .fields-column {
    display: flex;
    flex-direction: column;
  }

  .fields-row {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }

  .rsvp-field {
    &:not(.first) {
      margin-top: 16px;
    }

    &.last {
      margin-bottom: 16px;
    }

    .rsvp-field-label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
      .asterisk {
        margin-left: 2px;
        font-size: 18px;
        line-height: 1;
      }
    }
  }

  .rsvp-checkbox-container {
    margin-top: 16px;
  }

  .check-label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 1.5;

    .inline-link {
      display: inline-block;
      font-size: 12px;
      color: #015637;
      line-height: 1.5;
    }
  }

  .required-field-msg {
    color: #ba1414;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
    height: 30px;

    &.bottom {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

// .modal-backdrop {
//   backdrop-filter: blur(3px);
//   background-color: #010c138c;
// }
// .modal-backdrop.in {
//   opacity: 1 !important;
// }

.modal-open .ed-main {
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.mob-only {
  display: none;
}

@media (max-width: 992px) {
  .mob-only {
    display: block;
  }
  .ed-wrapper {
    .img-fluid {
    }

    p {
    }

    .section-heading {
      &.sm {
      }
    }

    .rsvp-button {
      &:hover {
      }
    }

    .ed-block-container {
      .ed-vertical-flex {
        .ed-left-container {
        }

        .ed-right-rail {
          display: none;
          .sticky-content {
          }
        }
      }
    }

    .ed-main {
    }

    .horizontal-rule {
      &.hero {
        display: none;
      }
    }

    .header-bar {
      .header-container {
        justify-content: center;
        .header-title {
        }

        .header-logos {
          justify-content: space-between;
          width: 100%;
          flex-wrap: nowrap; /* Prevents wrapping of logos */
          &.right {
            display: none;
          }
          .header-logo {
            flex: 1 1 auto; /* Allows logos to grow and shrink equally */
            &.lg {
            }

            &:not(:first-child) {
            }
          }
        }
      }
    }

    .ed-hero {
      padding-bottom: 40px;
      .hero-info-container {
        text-align: center;
        .hero-location {
          margin-left: auto;
          img {
          }
        }

        .hero-heading {
        }

        .hero-subheading {
        }

        .hero-text {
        }

        .expect-bullets {
          text-align: left;
          margin-left: auto;
          margin-right: auto;
          width: fit-content;
          li {
          }
        }

        .expect-disclaimer {
          width: 80%;
          margin: auto;
          margin-top: 30px;
          margin-bottom: 0;
        }

        .rsvp-button {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .ed-event-info {
      .event-inner {
        .event-image {
          img {
          }
        }

        .event-info-container {
          .event-details-heading {
          }

          .event-info {
            .event-info-text {
              .start {
              }
            }
          }
        }
      }
    }

    .ed-expect {
      .expect-title {
      }

      .expect-text {
      }

      .expect-bullets {
        li {
        }
      }

      .expect-disclaimer {
      }

      .ed-faqs {
        .ed-faq-title {
        }

        .faq {
          &.first {
          }

          .faq-head {
            &.active::before {
            }

            &::before {
            }
          }

          .faq-body {
            &.active {
            }

            p {
              a {
              }
            }
          }
        }
      }
    }

    .footer-spacer {
    }

    .ed-footer {
    }
  }

  @keyframes scale-in {
  }

  .modal.scale-in .modal-dialog {
  }

  .modal.scale-out .modal-dialog {
  }

  @keyframes scale-out {
  }

  .rsvp-modal {
    .form-control {
    }

    .form-control:focus {
    }

    .check__box {
      span {
      }

      input:checked ~ span {
      }
    }

    .modal-content {
    }

    .rsvp-modal-title {
      &.sm {
      }
    }

    .rsvp-submitted-text {
    }

    .rsvp-modal-close {
      &:hover {
      }
    }

    .register-button {
      &:hover {
      }
    }

    .fields-column {
    }

    .fields-row {
    }

    .rsvp-field {
      &:not(.first) {
      }

      &.last {
      }

      .rsvp-field-label {
        .asterisk {
        }
      }
    }

    .rsvp-checkbox-container {
    }

    .check-label {
      .inline-link {
      }
    }

    .required-field-msg {
      &.bottom {
      }
    }
  }

  .modal-open .ed-main {
  }
}
