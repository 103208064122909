// colors
$primary: #A7E2EE;
$primary-dark: #00a6cb;
$main : #002B49;
$black: #000;
$white: #fff;
// fonts
$mainFont: 'FoundersGrotesk';



