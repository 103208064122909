@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

.israel-deal-new {
}

.israel-dealportal {
  &.new {
    .req-item .req-item-content {
      padding-top: 27px;
      padding-bottom: 24px;
      padding-right: 27px;
    }

    .startup-row.top {
      padding-bottom: 13px;
      border-bottom: 1px solid #00000029;
      margin-bottom: 10px;
    }

    .startup-row {
      margin-left: 13px;
    }

    .check__box.adj {
      // width: 27px;
      // height: 22px;
      // min-width: 27px;
      // min-height: 22px;
      background-color: #fff;
      margin: 0;
    }

    .check {
      margin-top: 20px;
    }

    .check__box {
      margin: 0 39px 9px 0;
      width: 21px;
      height: 21px;
      min-width: 21px;
      min-height: 21px;
      cursor: pointer;

      span {
        border: 2px solid #ff38c2;
      }
    }

    .check-label {
      font-size: 18px;
      margin-top: 0;
      font-weight: 400;
      transform: translateY(-2px);
    }

    .check__box input:checked ~ span {
      background-color: #ff38c2;

      img {
        // -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        // filter: invert(100%);
      }
    }

    .header-text {
      margin-top: 40px;
    }

    .request {
      padding-top: 0;
    }

    .option-container {
      .outer-container {
        display: flex;
        flex-direction: row;

        .checkbox-container {
          background-image: url(https://i.imgur.com/CC3UZue.png);
          background-repeat: no-repeat;
          background-size: cover;
          padding: 20px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding-top: 34px;
          // &.bg-0 {
          //   background-position: 0px;
          // }

          // &.bg-1 {
          //   background-position: -20px;
          // }

          // &.bg-2 {
          //   background-position: -40px;
          // }

          // &.bg-3 {
          //   background-position: -60px;
          // }

          // &.bg-4 {
          //   background-position: -80px;
          // }

          // &.bg-5 {
          //   background-position: -100px;
          // }

          // &.bg-6 {
          //   background-position: -120px;
          // }

          // &.bg-7 {
          //   background-position: -140px;
          // }

          // &.bg-8 {
          //   background-position: -160px;
          // }

          // &.bg-9 {
          //   background-position: -180px;
          // }

          // &.bg-10 {
          //   background-position: -200px;
          // }
        }
      }
    }

    @media (max-width: 767px) {
      .hero__inner h1 {
        font-size: 60px;
      }
      // .startup-row.top {
      //   display: flex;
      //   flex-direction: column;
      // }
      .startup-field {
        text-align: left;
        margin-top: 10px;
        font-weight: 700;
      }
      .startup-img-col {
        justify-content: start;
        justify-self: flex-start;
        padding-bottom: 16px;
      }

      .startup-img {
        max-width: 200px;
      }
    }
  }

  .req-item .req-item-content {
    padding-top: 17px;
    padding-bottom: 11px;
    padding-right: 27px;
  }
  .startup-info-container {
    display: flex;
    flex-direction: column;
  }

  .startup-img-col {
    align-self: center;
    justify-content: end;
    justify-self: flex-end;
    display: flex;
  }

  .startup-img {
    width: 100%;
    align-self: center;
    max-width: 142px;
    text-align: right;
  }

  .short-desc-container {
    display: flex;
    align-items: end;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
  }

  .row-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .startup-row {
    margin-left: 7px;
    display: flex;
    align-items: start;
    margin-bottom: 4px;

    &.top {
      align-items: end;
      padding-bottom: 4px;
      border-bottom: 1px solid #00000029;
      margin-bottom: 10px;
    }

    a {
      color: #0000ff;
      line-height: 1.4;
    }
  }

  .startup-field {
    text-align: right;
  }

  .startup-data {
  }
}
