@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

html,
body {
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($color: #000, $alpha: 0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba($color: #000, $alpha: 0.3);
}
/*** 
====================================================================
Global Settings
====================================================================
   ***/
body {
  font-family: $mainFont;
  font-size: 16px;
  color: $main;
  line-height: 20px;
  font-weight: 400;
  background: $white;
  &.active {
    overflow: hidden;
  }
}
a {
  text-decoration: none;
  opacity: 1;
  color: $white;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.88;
  }
}
ul {
  li {
    list-style: none;
  }
}
.auto__container {
  position: relative;
  max-width: 1370px;
  margin: 0 auto;
  padding: 0 40px;
  &.nav-side-container {
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.btn {
  border: 2px solid transparent;
  border-radius: 0.625rem;
  padding: 0.5rem 1.4375rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  line-height: normal;
}
.btn-primary {
  background: #a7e2ee;
  border-color: #a7e2ee;
  color: #002b49;
}
.btn-primary:hover {
  background: transparent;
  border-color: #00a6cb;
  color: #00a6cb;
}
.btn-check.nice-select.open + .btn-primary,
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary.nice-select.open,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: #00223a;
  border-color: #002037;
  color: #fff;
}
