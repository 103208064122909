@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

.industrial-tech {
  :target {
    scroll-margin-top: 80px; /* Total height of the navbar including padding */
  }
  .request {
    padding: 0;

    &.top {
      padding-top: 18px;
    }

    &.last {
      padding-bottom: 62px;
      // background-color: #fff;
      padding-top: 20px;

      .input input {
        background-color: #ffffff;
      }
    }

    &.final {
      padding-bottom: 43px;
    }
  }

  .input textarea {
    background-color: #fff;
  }

  .input.choose-file-input-outer {
    justify-content: flex-start;
  }

  .requestForm {
    padding: 44px 64px 22px;
    background-color: unset;
    h3 {
      margin-bottom: 0;
    }

    .request-container {
      background-color: #fff;
      padding: 30px 16px 0px 34px;
    }

    .input__outer {
      margin-bottom: 25px;
    }
  }

  .overview-section {
    width: 100%;
    background-color: #fff;
  }
  .topics-section {
    width: 100%;
    background-color: #e2faff;
    padding: 30px;
    padding-top: 46px;
    padding-bottom: 46px;

    .auto__container {
      max-width: 947px;
    }
    .topic-container {
      color: #002b4b;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 400px;
      margin: auto;

      .title {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 8px;
      }

      .desc {
        font-size: 18px;
        margin-bottom: 10px;
        padding-left: 16px;
        padding-right: 16px;
      }

      img {
        // max-width: 400px;
      }

      .action-btn {
        border-radius: 6px;
        background-color: #a7e2ee;
        padding: 6px;
        padding-left: 37px;
        padding-right: 37px;
        font-size: 22px;
        height: 38px;
        line-height: 1;
        font-weight: 400;
        text-align: center;
        margin-top: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.15s ease;

        div {
          transform: translateY(2px);
        }

        &:hover {
          background-color: #abc6e7;
        }
      }
      // lg
      @media (max-width: 991px) {
        .action-btn {
          margin-bottom: 85px !important;
          font-size: 2rem;
          height: 60px;

          div {
            transform: translateY(5px);
          }
        }
      }
    }
  }

  .section-header-text {
    max-width: 947px;
    margin: 0 auto;
    padding: 0 30px 30px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative;
    background-color: #fff;

    .title {
      font-size: 30px;
      font-weight: 400;
    }
    .sub-text {
      font-size: 18px;
    }
  }

  .request-heading {
    font-size: 38px;
    width: 100%;
  }

  .request-subheading {
    font-weight: 400;
    margin-bottom: 17px;
    width: 100%;
    // font-style: italic;
  }

  .request-heading-sm {
    font-size: 28px;
    width: 100%;
    margin-bottom: 16px;
  }

  .form-subbox {
    margin-top: 9px;
  }

  .hero__inner p {
    margin-bottom: 25px;
  }

  .intro-text {
    font-size: 46px;
    line-height: 1;

    &.sm {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .span-leadspace-home__spanner {
    border-bottom-color: #00a6cb00;
  }

  .hero__inner {
    min-height: calc(97vh - 442px);
    min-height: calc(50vh);
    min-height: 0;
    padding-top: 94px;
    padding-bottom: 63px;
  }

  .events {
    padding: 52px 0;
    padding-top: 41px !important;
  }

  .events .auto__container {
    display: block;
    z-index: 2;
  }

  .hero-circle {
    top: 15%;
    right: 2%;
    width: 44%;
    max-width: 251px;
    z-index: 1;

    top: 15%;
    right: 10%;
    width: 44%;
    max-width: 259px;
    z-index: 1;
  }

  .short-desc {
    font-weight: 700;
  }

  .long-desc {
    font-weight: 400;
  }

  .requestFormItem__inner {
    padding-bottom: 50%;
  }

  .company {
    padding: 20px 0;

    .company__inner h3 {
      font-size: 36px;
      margin-bottom: 36px;
    }
  }

  .company-img {
    position: absolute;
    top: 7%;
    right: 5%;
    width: 20%;
    max-width: 105px;
  }

  .req-item {
    // Styles for the main container div
    position: relative;
    width: 100%;
    height: fit-content;
    margin-bottom: 8px;

    &.event {
      padding-right: 8px;

      .req-item-upper {
        .req-item-img {
          width: 20%;
          padding-right: 8px;
          margin-right: 8px;
        }
      }
    }

    .req-item-content {
      // position: absolute;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
      padding: 15px 19px 12px;
      background-color: #fff;
      // text-align: center;
      // display: flex;
      // flex-direction: column;
      // align-items: start;
      // justify-content: center;
      transition: 0.3s ease;
    }

    input[type="checkbox"] {
      // Styles for the checkbox input
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
      &:hover ~ .req-item-content {
        background-color: #d2f7ff;
      }
      &:checked ~ .req-item-content {
        background-color: $primary;
        // color: $white;

        .long-desc {
          // color: #c9d4d6;
        }
      }
    }

    .req-item-upper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .req-item-img {
        width: 17%;
        padding-right: 24px;
      }
      @media (max-width: 750px) {
        .req-item-img {
          width: 23%;
          padding-right: 11px;
        }
      }

      .req-item-header {
        display: flex;
        flex-direction: column;
        width: 100%;

        h5 {
          margin-bottom: 0;
        }

        .sm.short-desc {
          margin-bottom: 1px;
          font-weight: 500;
        }

        .sm.long-desc {
          color: rgb(43, 43, 43);
        }
      }
    }

    .req-item-lower {
      // Styles for the lower section div
      width: 100%;

      .sm.long-desc {
        // Styles for the long description paragraph
      }
    }
  }
}
